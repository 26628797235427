@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('./assets/fonts/MarkPro-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('./assets/fonts/MarkPro-Book.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('./assets/fonts/MarkPro-Black.ttf') format('truetype');
  font-display: swap;
}

@layer utilities {
  .lozenge {
    clip-path: polygon(7% 0, 100% 0, 91% 100%, 0 100%);
  }
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#_root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
  font-family: 'MarkPro-Bold SKY';
  font-weight: normal;
}

.info-text,
.main-content,
.current-highlight {
  opacity: 0;
  transition: opacity 0.3s;
}

.info-text.show,
.main-content.show,
.current-highlight.show {
  opacity: 1;
  transition: opacity 0s;
}

.slide {
  padding-bottom: 10px;
  margin-left: 4px;
}

.slide .bg-white {
  height: 600px !important;
}

.brightcove-react-player-loader video-js {
  width: 100%;
  height: 41.2vw;
  max-height: 818px;
  min-height: 390px;
}

.brightcove-react-player-loader video-js iframe {
  width: 100%;
  height: 100%;
}
.product-slider .slick-track {
  display: flex;
}

.product-slider .slick-slide {
  height: inherit !important;
  margin-right: 0px;
}
.product-slider .slick-disabled {
  opacity: .1;
}
.product-slider .slick-slide > div,
.product-slider .slick-slide > div > div,
.product-slider .slick-slide > div > div > div {
  height: 100%;
}

.large-gallery .slick-dots button::before {
  color: white !important;
  font-size: 10px;
  margin-top: 10px;
}

.large-gallery .slick-slide {
  height: 1300px;
}

.section-card-panel h2 {
  font-size: 52px !important;
  line-height: 60px !important;
  font-weight: 900 !important;
  font-family: 'MarkPro-Black SKY' !important;
}

@media screen and (max-width: 768px) {
  .section-card-panel h2 {
    font-size: 36px !important;
    line-height: 40px !important;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.section-card-panel h2 b {
  background-image: linear-gradient(110.93deg, #F26522 0%, #F96DB1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.pinch-panel {
  transition: all .5s;
}

.pinch-panel:hover {
    transform: scale(.96);
    transform-origin: center center;
    transition: all .5s;
}
.more-choice-for-you-section-carousel .slick-track {
  padding-top: 0px!important 
}
@media screen and (max-width: 600px) {
  .slick-slide-mob .slick-list{
  padding:0 !important;
  /* width:440px !important; */
}
  }


  @media only screen and (width:390px) {
    .iPhone-12-CTA-button{
      margin: .5rem 3.5rem .5rem 1rem !important;
    }
}

.video-js{
  width: 100%;
  height: 100%;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #00013a !important;
  font-family: 'Arial', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #00013a;
}

.embeddedServiceHelpButton .uiButton {
  margin: -20px 0px 0px 0px !important;
  min-width: auto !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 8px !important;
}

.embeddedServiceHelpButton .embeddedServiceIcon::before {
  font-size: 2.25em !important;
}

.embeddedServiceHelpButton .embeddedServiceIcon {
  margin-right: 0px !important;
}

.embeddedServiceHelpButton .helpButtonDisabled {
  display: none !important;
}

@media (max-width: 767px) {
  .embeddedServiceHelpButton .uiButton {
    margin: -75px 0px 0px 100px !important;
  }

  .embeddedServiceHelpButton .embeddedServiceIcon::before {
    font-size: 3.25em !important;
  }
}
.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 100px !important;
 display: flex !important;
 flex-direction: row-reverse !important;
 background: #00013a !important;
 width: 103px !important;
  height: 48px !important;
  padding: 14px 16px !important;
  font-size: 10px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}
.embeddedServiceHelpButton .helpButton {

  border-radius: 100px !important;
  bottom:20px !important;
}
.embeddedServiceHelpButton {
  position: fixed !important;
   left: 85% !important;


          /* right: 40px !important; */

  }
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message{

 font-size: 0px !important;
}
.embeddedServiceHelpButton .uiButton .helpButtonLabel .message::after{
 content: 'Chat';
font-size: 16px !important;
font-weight: 700 !important;
line-height: 125% !important;
font-family: "Mark Pro" !important;
font-style: normal !important;
}

@media (max-width: 520px) {
  .embeddedServiceHelpButton .helpButton .uiButton {
    padding: 8px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
    border-radius: 10px 10px 0px 0px !important;
    text-align: center !important;
 font-family: "Mark Pro" !important;
 font-size: 1em !important;
 font-style: normal !important;
 font-weight: 700 !important;
 line-height: 125% !important;
    flex-direction: row !important;

  }
  .embeddedServiceHelpButton .helpButton {

   transform: rotate(-90deg) !important;
   display: inline-flex !important;
   bottom: 3vh !important;
 position: fixed !important;
 left:  92% !important;
 width: 164px !important;
 height: 40px !important;


 }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel .message{
    overflow: auto !important;


  }
  .embeddedServiceHelpButton .uiButton .helpButtonLabel{
    overflow: auto !important;
    transform: rotate(90deg);
  }
  .embeddedServiceHelpButton .uiButton .embeddedServiceIcon {
    transform: rotate(90deg);
  }

}
@media only screen and (min-width: 520px) and (max-width: 1024px){
  .embeddedServiceHelpButton .helpButton .uiButton{
    padding: 14px 16px !important;
  }


  }
  @media only screen and (min-width: 325px) and (max-width: 1440px){

    .embeddedServiceHelpButton .embeddedServiceIcon::before {
      font-size: 24px !important;
    }
    .embeddedServiceHelpButton .embeddedServiceIcon{
      font-size: 24px !important;
    }
    }

    @media (min-width: 1024px) and (max-width: 1340px) {
 .bigscreen{
  background-size: 100% 100% !important;
 }
    }
    @media (min-width: 1601px) and (max-width: 1999px) {
 .bigscreen{
  background-size: 100% 100% !important;
 }
    }
    @media (min-width: 2000px) and (max-width: 2075px) {
 .bigscreen{
  background-size: 100% 100% !important;
 }
    }
